import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import "./Vermoegensbilanz.css"

import { useUser } from '../../context/Usercontext';
import { useDevice } from '../../context/Devicecontext';

import RequestHelper from '../../components/requesthelper/RequestHelper';

import DropdownMenu, { DropdownProps } from '../../components/dropdownmenu/DropdownMenu';

import PdfViewer from '../../components/pdf/PDFViewer';

import useLoadingLocal from '../../components/loading/hook/LoadingLocal';
import LoadingKompass from '../../components/loading/kompass/LoadingKompass';

import { toastEventEmitter } from '../../components/toast/ToastEmitter';

const Vermoegensbilanz: React.FC = () => {
    const { user } = useUser();
    const navigate = useNavigate();

    const [ isLoading, startLoading, stopLoading ] = useLoadingLocal();

    const [pdfUrl, setPdfUrl] = useState<string | null>(null);
    const [debugLogUrl, setDebugLogUrl] = useState<string | null>(null);

    const [ablage, setAblage] = useState<DropdownProps["options"]>([]);
    const [selectedAblage, setSelectedAblage] = useState<{ id: string; name: string } | null>(null);

    const [vermoegensbilanzDatum, setVermoegensbilanzDatum] = useState<DropdownProps["options"]>([]);
    const [selectedVermoegensbilanzDatum, setSelectedVermoegensbilanzDatum] = useState<{ id: string; name: string } | null>(null);

    const handleAblageSelected = (selectedOption: { id: string; name: string }) => {
        setSelectedAblage(selectedOption); // Update state in the parent component
        console.log("Selected Ablage:", selectedOption); // Do something with the option

        startLoading();
        RequestHelper.post('/vermoegenstabelle/datelist', selectedOption) // Replace with your API endpoint
            .then(response => {
                stopLoading();
                const data = response.data;
                console.log(data);
                setVermoegensbilanzDatum(
                    data.map((item: { id: string; name: string }) => ({
                        name: item.name, // Use the appropriate property for display
                        id: item.id, // Use the appropriate property for value
                    }))
                );
            })
            .catch(error => {
                stopLoading();
                console.error('Error fetching datelist of the table', error);
                toastEventEmitter.addToast("Konnte die Vermögensbilanz nicht auslesen. Überprüfen sie, ob die Tabelle korrekt ist.", 3000, 'error')
            });

    };

    const handleDatumSelected = (selectedDatum: { id: string; name: string }) => {
        console.log("Selected Datum:", selectedDatum); // Do something with the option
        setSelectedVermoegensbilanzDatum(selectedDatum); // Update state in the parent component
    };

    useEffect(() => {
        startLoading();
        RequestHelper.post('/sharedDrivesList') // Replace with your API endpoint
            .then(response => {
                stopLoading();
                const data = response.data;
                console.log(data);
                setAblage(
                    data.map((item: { id: string; name: string }) => ({
                        name: item.name, // Use the appropriate property for display
                        id: item.id, // Use the appropriate property for value
                    }))
                );
            })
            .catch(error => {
                stopLoading();
                console.error('Error fetching shared drives', error);
                toastEventEmitter.addToast("Konnte keine geteilten Ablagen auslesen", 3000, 'error')
            });
    }, []);

    useEffect(() => {
        if (ablage.length > 0) {
            handleAblageSelected(ablage[0]);
        }
    }, [ablage, setSelectedAblage]);


    useEffect(() => {
        if (vermoegensbilanzDatum.length > 0) {
            setSelectedVermoegensbilanzDatum(vermoegensbilanzDatum[0]);
        }
    }, [vermoegensbilanzDatum, setSelectedVermoegensbilanzDatum]);


    // USER -------------------
    useEffect(() => {
        console.log(user)
        if (!user) 
        {
            navigate('/signin'); // Umleitung auf die Startseite oder eine andere Seite
        }
    }, [user, navigate]);

    if (!user) {
        return null; // Rückgabe von null, damit kein Inhalt angezeigt wird, während weitergeleitet wird
    }
    // USER END -------------------

    function NormalPDFCreate() {
        const result = {
            "ablage": selectedAblage,
            "datum": selectedVermoegensbilanzDatum,
            "extended": false
        };

        startLoading();

        RequestHelper.post('/vermoegenstabelle/create/pdf', result, {
            responseType: 'blob',
        })
            .then(response => {
                stopLoading();
                const url = URL.createObjectURL(response.data);
                setPdfUrl(url);
                toastEventEmitter.addToast("PDF erstellt", 3000, 'success')
            })
            .catch(error => {
                stopLoading();
                setPdfUrl(null);
                console.error('Error creating pdf', error);
                toastEventEmitter.addToast("PDF konnte nicht erstellt werden", 3000, 'error')
            });
    }

    function ExtendedPDFCreate() {
        const result = {
            "ablage": selectedAblage,
            "datum": selectedVermoegensbilanzDatum,
            "extended": true
        };

        startLoading();

        RequestHelper.post('/vermoegenstabelle/create/pdf', result, {
            responseType: 'blob',
        })
            .then(response => {
                stopLoading();
                const url = URL.createObjectURL(response.data);
                setPdfUrl(url);
                toastEventEmitter.addToast("PDF erstellt", 3000, 'success')
            })
            .catch(error => {
                stopLoading();
                setPdfUrl(null);
                console.error('Error creating pdf', error);
                toastEventEmitter.addToast("PDF konnte nicht erstellt werden", 3000, 'error')
            });
    }

    function DebugLogGet() {
        const result = {
            "ablage": selectedAblage,
            "datum": selectedVermoegensbilanzDatum,
        };

        startLoading();

        RequestHelper.post('/vermoegenstabelle/debuglog', result, {
            responseType: 'blob',
        })
            .then(response => {
                stopLoading();
                const url = URL.createObjectURL(response.data);
                setDebugLogUrl(url);
                toastEventEmitter.addToast("Log erhalten", 3000, 'success')
            })
            .catch(error => {
                stopLoading();
                setDebugLogUrl(null);
                console.error('Log konnte nicht angefordert werden', error);
                toastEventEmitter.addToast("Log konnte nicht angefordert werden", 3000, 'error')
            });
    }

    return (
        <div className='Vermoegensbilanz-container'>
            <LoadingKompass isLoading={isLoading} />
            <h1>Vermögensbilanz</h1>
            <div className='driveAuswahl'>
                <DropdownMenu
                    options={ablage}
                    selectedOption={selectedAblage}
                    onOptionSelected={handleAblageSelected}
                />
            </div>

            <div className='datumsAuswahl'>
                <DropdownMenu
                    options={vermoegensbilanzDatum}
                    selectedOption={selectedVermoegensbilanzDatum}
                    onOptionSelected={handleDatumSelected}
                />
            </div>
            <button onClick={NormalPDFCreate}>Erstelle PDF</button>
            <button onClick={ExtendedPDFCreate}>Erstelle PDF(Extended)</button>

            {pdfUrl &&
                <div>
                    <a href={pdfUrl} download={`${selectedAblage?.name}_${selectedVermoegensbilanzDatum?.name}_Vermögensbilanz.pdf`}>
                        Download PDF
                    </a>
                    <button onClick={DebugLogGet}>Erhalte Debug Log</button>
                </div>
            }
            {debugLogUrl &&
                <a href={debugLogUrl} download={`${selectedAblage?.name}_${selectedVermoegensbilanzDatum?.name}_Vermögensbilanz.log`}>
                    Download Log
                </a>
            }
            <div className='vb-pdf'>
                {pdfUrl &&
                    <PdfViewer fileUrl={pdfUrl}/>
                }
            </div>
        </div>
    );
};

export default Vermoegensbilanz;