/* Dieses Element stellt eine Auswahlzeile oben an der Seite dar */
/* Ist ein Ersatz für die Sidebar, da diese auf mobilen Geräten schlecht aussehen würde */

import React, {useState, useEffect} from 'react';
import './FolderView.css';

import RequestHelper from '../requesthelper/RequestHelper';

import { useUser } from '../../context/Usercontext';
import useLoadingLocal from '../loading/hook/LoadingLocal';

interface FileItem {
    id: string;
    name: string;
    mimeType: string;
    webViewLink?: string;       // Link zum Anzeigen
    webContentLink?: string;    // Link zum Herunterladen
}

const FolderView: React.FC = () => {
    const [folderStack, setFolderStack] = useState<string[]>([]);
    const [contents, setContents] = useState<FileItem[]>([]);

    const [ isLoading, startLoading, stopLoading ] = useLoadingLocal();

    const { user } = useUser();

    const fetchStartFolderId = async () => {        
        startLoading();

        RequestHelper.get('/getUserMainFolderId') // Replace with your API endpoint
        .then(response => {
            const data = response.data;
            setFolderStack([data.startFolderId]);
            stopLoading();
        })
        .catch(error => {
            console.error('Error fetching start folder ID', error);
            stopLoading();
        });
    };

    const fetchFolderContents = async (folderId: string) => {
        startLoading();

        RequestHelper.get<FileItem[]>(`/getUserFolderContent`, {
            params: { folderId }
            }) // Replace with your API endpoint
        .then(response => {
            const data = response.data;
            setContents(data);
            stopLoading();
        })
        .catch(error => {
            console.error('Error fetching folder contents', error);
            stopLoading();
        });
    };

    useEffect(() => {
        if (user) {  // Überprüfen, ob der Benutzer nicht null ist
            fetchStartFolderId();
        }
    }, [user]);

    useEffect(() => {
        if (folderStack.length > 0) {
            fetchFolderContents(folderStack[folderStack.length - 1]);
        }
    }, [folderStack]);

    const handleFolderClick = (folderId: string) => {
        setFolderStack([...folderStack, folderId]);
    };

    const handleBackClick = () => {
        setFolderStack(folderStack.slice(0, -1));
    };

    if (!user) {
        return <div>Bitte anmelden, um auf Google Drive zuzugreifen.</div>; // Anzeige, wenn der Benutzer null ist
    }

    return (
        <div className='folderview-content'>
            <button onClick={handleBackClick} disabled={folderStack.length <= 1} className="back-button">
                ← Zurück
            </button>
            <ul className="file-list">
                {isLoading ? (
                    <li>Lädt...</li>
                ) : contents.length === 0 ? (
                    <li>Dieser Ordner ist leer.</li>
                ) : (
                    contents.map((item) => (
                        <li
                            key={item.id}
                            className={item.mimeType === 'application/vnd.google-apps.folder' ? 'folder-item' : 'file-item'}
                            onClick={() => item.mimeType === 'application/vnd.google-apps.folder' && handleFolderClick(item.id)} // onClick auf li setzen
                        >
                            {item.mimeType === 'application/vnd.google-apps.folder' ? (
                                <span>{item.name} (Ordner)</span>
                            ) : (
                                <a
                                    href={item.webViewLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="file-link"
                                >
                                    {item.name} (Datei)
                                </a>
                            )}
                        </li>
                    ))
                )}
            </ul>
        </div>
    );
};

export default FolderView;

