import React, {useEffect} from 'react';

import "./TopbarToggle.css"

import { useSideTopBar } from '../../../context/SideTopBarcontext';

import { useDevice } from '../../../context/Devicecontext';

export function setTopBarDynamicHeight(): void {
    const containers = document.getElementsByClassName('main-content ');
    const headers_exp = document.getElementsByClassName('topbar expanded'); // Get all elements with class 'header'
    const headers_col = document.getElementsByClassName('topbar collapsed'); // Optional footer class

    if (containers.length > 0 && (headers_exp.length > 0 || headers_col.length > 0)) {
        const container = containers[0] as HTMLElement; // Get the first container
        const header_exp = headers_exp.length > 0 ? (headers_exp[0] as HTMLElement) : null;
        const header_col = headers_col.length > 0 ? (headers_col[0] as HTMLElement) : null;

        requestAnimationFrame(() => {
            const headerHeight = header_exp ? header_exp.offsetHeight : (header_col ? header_col.offsetHeight : 0); // Dynamically get the height

            // Adjust the height of the container
            (container as HTMLElement).style.top = `${headerHeight}px`;
        });
    }
}

const TopbarToggle: React.FC = () => {
    const { isTopbarExpanded, setIsTopbarExpanded } = useSideTopBar();
    const { isMobile } = useDevice();

    useEffect(() => {
        if (isMobile) {
            const containers = document.getElementById('main-content');
            (containers as HTMLElement).style.top = `66px`;
        }
    }, [isMobile]);

    return (
        <button onClick={() => {
            setIsTopbarExpanded(!isTopbarExpanded);
            setTopBarDynamicHeight();
        }} className={`topbar-toggle-button ${isTopbarExpanded ? 'expanded' : 'collapsed'}`}>
            {!isTopbarExpanded && <img src='/icons/burger-menu.svg' alt='ausklappen' />}
            {isTopbarExpanded && <img src='/icons/close.svg' alt='einklappen' />}
        </button>
    );
};

export default TopbarToggle;