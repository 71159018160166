import React, { useState } from 'react';

import useLoadingLocal from '../loading/hook/LoadingLocal';
import LoadingKompass from '../loading/kompass/LoadingKompass';

import RequestHelper from '../requesthelper/RequestHelper';

import { toastEventEmitter } from '../toast/ToastEmitter';

const FileUpload: React.FC = () => {
  const [isLoading, startLoading, stopLoading] = useLoadingLocal();
  const [files, setFiles] = useState<File[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileArray = Array.from(event.target.files);
      setFiles(fileArray);
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    // Füge alle Dateien zur FormData hinzu
    files.forEach(file => {
      formData.append('files', file); // 'files' ist der Schlüssel, den du im Flask-Backend verwenden wirst
    });

    try 
    {
      startLoading();

      const response = await RequestHelper.post('/uploadFiles', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if(response.status == 200)
      {
        console.log('Upload successful:', response);
        stopLoading();
        toastEventEmitter.addToast("Upload erfolgreich", 3000, 'success')
      } 
      else 
      {
        console.error('Upload failed:', response.statusText);
        stopLoading();
        toastEventEmitter.addToast("Fehler beim Upload", 3000, 'error')
      }
    } catch (error) {
      console.error('Error during upload:', error);
      stopLoading();
      toastEventEmitter.addToast("Fehler beim Upload", 3000, 'error')
    }
  };

  return (
    <div>
      <LoadingKompass isLoading={isLoading}/>
      <input
        type="file"
        multiple
        accept="image/*,.pdf,.docx,.txt"
        onChange={handleFileChange}
      />

      <button onClick={handleSubmit}>Hochladen</button>

      {/* <div>
        <h4>Selected Files:</h4>
        <ul>
          {files.map((file, index) => (
            <li key={index}>{file.name}</li>
          ))}
        </ul>
      </div>*/}
    </div> 
  );
};

export default FileUpload;
