import { useState, useCallback } from "react";

function useLoadingLocal(): [boolean, () => void, () => void] {
  const [isLoading, setIsLoading] = useState(false);

  const startLoading = useCallback(() => setIsLoading(true), []);
  const stopLoading = useCallback(() => setIsLoading(false), []);

  return [isLoading, startLoading, stopLoading];
}

export default useLoadingLocal;