// ToastProvider.tsx
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ToastContainer from './ToastContainer';
import { toastEventEmitter } from './ToastEmitter';

interface ToastData {
  id: number;
  message: string;
  duration: number;
  type: 'success' | 'error' | 'warning';
}

const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastData[]>([]);
  const [toastRoot, setToastRoot] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    // Dynamischer Container innerhalb des `root`-Divs
    const toastDiv = document.createElement('div');
    toastDiv.id = 'toast-root';
    document.getElementById('root')?.appendChild(toastDiv);
    setToastRoot(toastDiv);

    return () => {
      document.getElementById('root')?.removeChild(toastDiv);
    };
  }, []);

  useEffect(() => {
    const handleAddToast = (event: Event) => {
      const customEvent = event as CustomEvent; // Typumwandlung auf CustomEvent
      setToasts((prevToasts) => [...prevToasts, customEvent.detail]);
    };
  
    const handleRemoveToast = (event: Event) => {
      const customEvent = event as CustomEvent;
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== customEvent.detail));
    };
  
    toastEventEmitter.addEventListener('addToast', handleAddToast);
    toastEventEmitter.addEventListener('removeToast', handleRemoveToast);
  
    return () => {
      toastEventEmitter.removeEventListener('addToast', handleAddToast);
      toastEventEmitter.removeEventListener('removeToast', handleRemoveToast);
    };
  }, []);

  return (
    <>
      {children}
      {toastRoot &&
        ReactDOM.createPortal(
          <ToastContainer
            toasts={toasts}
            onRemoveToast={(id) => toastEventEmitter.removeToast(id)}
          />,
          toastRoot
        )}
    </>
  );
};

export default ToastProvider;
