import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

interface PdfViewerProps {
    fileUrl: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ fileUrl }) => (
    <Worker workerUrl="/js/3.11.174pdf.worker.min.js">
        <Viewer fileUrl={fileUrl} />
    </Worker>
);

export default PdfViewer;
