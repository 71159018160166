import React, { useRef, useEffect, useState } from 'react';
import './UserInitLoader.css';

const UserInitLoader: React.FC = () => {
    return (
        <div className='userinit-container'>
            Wir erstellen ihren User.
            Sie sofort weitergeleitet, wenn die Erstellung fertig ist
            *Loading Bar*
        </div>
    );
}

export default UserInitLoader;